@import "app/styles/getty/Legacy.module.scss";
@import "app/styles/getty/CheckboxField.module.scss";
@import "app/styles/getty/Zindex.module.scss";
@import "app/styles/getty/PriorDownload.module.scss";

$agreement-height: 2.5rem;

.galleryFixedAsset {
  width: 195px;
  min-height: 380px;
  margin: {
    top: -1px;
    left: -1px;
  }
  padding: {
    right: 0.5em;
    left: 0.5em;
  }

  display: flex;
  flex-direction: column;

  background-color: $gi-white;
  border: 1px solid $grey-40;

  .container {
    position: relative;
    border-bottom: 1px solid $grey-30;
  
    .link {
      position: relative;
      cursor: pointer;
      display: block;
      padding: {
        top: 0.5em;
        bottom: 0.5em;
      }

      .figure {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 184px;
        width: 100%;
      
        &Archive {
          background-color: $grey-50;
        }
      
        figcaption {
          display: none;
        }

        .thumb {
          display: block;
          max-height: 184px;
          max-width: 177px;
        
          transition: opacity $complex-duration ease;
        
          &_BBA {
            width: 80%;
          }
        
          &_ITNA {
            width: 25%;
          }
        
          &_NNBA {
            width: 80%;
          }
        }
      }
    }
  }

  .priorDownload {
    @extend %prior-download;
    margin: .5rem;
  }
}

.clipBadge {
  border-radius: 2px;
  left: -9px;
}

.batchDownloadCheckbox {
  opacity: 0;
  position: absolute;
  top: 0.5em;
  right: 0.5em;

  .galleryFixedAsset:hover & {
    opacity: 1;
  }

  .galleryFixedAsset:focus-within & {
    opacity: 1;
  }

  input {
    background-color: $gi-white;
    cursor: pointer;
  }
  
  &Visible {
    opacity: 1;
  }
}

.hasFooter {
  position: relative;
  padding-bottom: $agreement-height;

  .footer {
    position: absolute;
    left: 0.5rem;
    bottom: 0;
    right: 0.5rem;
  
    display: flex;
    align-items: center;
    justify-content: center;
    height: $agreement-height;
  
    font-size: 0.875em;
    border-top: 1px solid $grey-30;
  
    .agreementDownload {
      padding-right: 5px;
      &Disabled {
        color: $grey-50;
      }
    }
  }
}

.checkbox {
  @extend .checkbox-field__input
}

.inBoard {
  position: absolute;
  bottom: 0.5rem;
  right: 0.25rem;

  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  color: $gi-white;
  background-color: rgba($gi-black, 0.6);
  border: 1px solid rgba($grey-70, 0.4);
  border-radius: 5px;
  visibility: visible;
  opacity: 1;
  transition: all $complex-duration ease;

  .galleryAsset:hover & {
    visibility: hidden;
    opacity: 0;
  }

  &Offline {
    bottom: 0.75rem;
  }
}

.pictureContainer {
  position: relative;
}